<!-- 搜索页面 -->
<template>
  <div class="search">
    <!-- 搜索框 -->
    <div class="search-box">
      <input v-model="keyword" type="text" placeholder="点击输入关键词" />
      <span class="btn-search" @click="onSearch()">搜索</span>
    </div>
    <!-- 搜索内容 -->
    <div class="searchContent" v-show="searchShow">
      <div
        class="sContent"
        v-for="(item, index) in this.searchData"
        :key="index"
        v-show="searchContentShow"
      >
        <div class="scenicItem" @click="backPrev()">
          <div class="leftItem">
            <div class="itemIcon">
              <img
                :src="$global.ImgUrl + item.webPoiTypeVos[0].listIcon"
                alt=""
              />
            </div>
            <div class="itemName">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="noResult" v-show="noResultShow">
        查不到相关数据，换个词试试~
      </div>
    </div>
    <!-- 历史框 -->
    <div class="histroy">
      <div class="leftName">历史记录</div>
      <div class="icon_del" @click="clearHistroy"></div>
    </div>
    <!-- 历史记录内容 -->
    <div class="histroyContent">
      <div class="histroyList" v-for="(item, index) in searchList" :key="index">
        <div class="icon_scenic" v-show="iconShow">
          <!-- <img :src="$global.ImgUrl + item.webPoiTypeVos[0].listIcon" alt="" /> -->
        </div>
        <span class="areaName">{{ item }}</span>
      </div>
    </div>
    <!-- 返回上一页 -->
    <div class="back" @click="prev"></div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { searchPoiApi } from "../../request/api/index";
import { Indicator, MessageBox } from "mint-ui";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      keyword: "", //搜索框输入的内容
      searchList: [], //搜索历史列表
      searchData: [], //搜索的数据
      searchShow: false, //显示搜索的内容
      searchContentShow: true, //搜索到的结果
      noResultShow: false, //搜索不到结果
      iconShow: true, //显示历史记录的图标
      languageType: 1, //语言种类
      scenicId: null, //从景点传过来的id
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  created() {},
  methods: {
    //搜索
    onSearch() {
      this.searchShow = true;
      Indicator.open(); //加载中
      const value = this.keyword.trim();
      if (!value) {
        //判断输入框的值是否为空  如果为空 直接返回不在执行
        return;
      }
      //如果local里不存在当前输入的关键词 则存到local里
      if (this.searchList.indexOf(value) === -1) {
        this.searchList.unshift(value);
        //历史记录最多只取十个
        this.searchList = this.searchList.slice(0, 10);
        localStorage.setItem("keyword", JSON.stringify(this.searchList));
      }
      this.getSearchPoiData(); //获取搜索的内容
    },
    // 清楚历史记录
    clearHistroy() {
      MessageBox({
        $type: "confirm",
        message: "是否确定清除历史记录?",
        title: "提示",
        showCancelButton: true,
      })
        .then((res) => {
          console.log(res);
          if (res == "confirm") {
            localStorage.removeItem("keyword"); // 清除本地存储的数据
            this.searchList = []; //data中的historyList 清除
          }
        })
        .catch((err) => {
          return;
        });
    },
    //请求景点搜索的数据
    getSearchPoiData() {
      searchPoiApi({
        languageType: this.languageType,
        name: this.keyword,
        scenicId: this.scenicId,
      }).then((res) => {
        console.log(res);
        if (res.status == "200") {
          this.searchData = res.data.data;
          console.log(this.searchData);
          if (this.searchData.length == 0) {
            this.searchContentShow = false;
            this.noResultShow = true;
          } else {
            this.searchContentShow = true;
            this.noResultShow = false;
          }
        }
        Indicator.close(); //加载完毕
      });
    },

    //返回上一页
    prev() {
      this.$router.go(-1);
    },
    //返回上一页打开相对应的景点
    backPrev() {
      this.$router.go(-1);
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 获取景区传递过来的id
    this.scenicId = this.$route.params.id;
    this.searchList = JSON.parse(localStorage.getItem("keyword")) || []; //先读取local里存储的历史记录
  },
};
</script>
<style lang="less">
.search {
  width: 100%;
  height: 100vh;
  padding: 0.9rem 1.6rem 0;
  position: relative;
  //搜索框
  .search-box {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    input {
      width: 29.5rem;
      height: 4.4rem;
      background: #fafafa;
      border-radius: 4.4rem;
    }
    .btn-search {
      width: 4rem;
      height: 3rem;
      font-size: 1.6rem;
      border-radius: 3rem;
      font-weight: 500;
      text-align: center;
      line-height: 3rem;
      color: #43525c;
    }
  }
  //搜索内容
  .searchContent {
    width: 100%;
    height: 100%;
    background: #fff;
    position: fixed;
    top: 7rem;
    .sContent {
      width: 85%;
      height: 5.6rem;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .scenicItem {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .leftItem {
          display: flex;
          .itemIcon {
            width: 2.2rem;
            height: 2.2rem;
            margin-right: 0.5rem;
          }
          .itemName {
            height: 2.2rem;
            line-height: 2.2rem;
            font-size: 1.6rem;
            color: #454e55;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
          }
        }
      }
    }
    .noResult {
      height: 3rem;
      margin-left: 7rem;
    }
  }
  //历史框
  .histroy {
    width: 100%;
    height: 3rem;
    margin: 3rem 0 1.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftName {
      font-size: 1.6rem;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      color: #444444;
    }
    .icon_del {
      width: 2.8rem;
      height: 2.8rem;
      background: url("./image/button_delete.svg") no-repeat;
      background-size: 100%;
    }
  }
  //历史记录内容
  .histroyContent {
    width: 100%;
    height: 5.6rem;
    .histroyList {
      height: 5.6rem;
      padding: 1.7rem 0;
      display: flex;
      align-items: center;
      .icon_scenic {
        width: 2.2rem;
        height: 2.2rem;
        background: chartreuse;
        margin-right: 0.5rem;
      }
      .areaName {
        height: 2.2rem;
        font-size: 1.6rem;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #454e55;
        line-height: 2.2rem;
        letter-spacing: 0rem;
      }
    }
  }
  //返回上一页
  .back {
    width: 3rem;
    height: 3rem;
    position: fixed;
    right: 0;
    bottom: 20rem;
    background: brown;
    z-index: 111;
  }
}
</style>